<style scoped>
  .text-danger {
    color: red !important;
  }
</style>
<template>
    <!-- HORIZONTAL LAYOUT -->
    <div class="vx-row">
        <div class="vx-col w-full mb-base">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Contract Code</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-input class="w-full" v-model="contract.Code" readonly/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6">
                    <span>Customer</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6">
                    <div class="vx-col sm:w-5/6 w-full">
                        <vs-input class="w-full" v-model="contract.CustomerName" disabled/>
                    </div>
                    <div class="vx-col sm:w-1/6 w-full">
                        <vs-button size="small" class="mr-3 mb-2" @click="browseCustomer()" :disabled="contract.StatusApproval ==1 ">Browse</vs-button>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Doc. Date</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <datepicker :inline="false" v-model="contract.Date" placeholder="Select Document Date" :disabled="contract.StatusApproval ==1"></datepicker>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Notes</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-textarea maxlength ="255" class="w-full" :label="charactersLeft" name="Notes" v-model="contract.Notes" :disabled="contract.StatusApproval ==1"/>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Effective Date</span>
                </div>
                <div class="vx-col vx-row sm:w-5/6 w-full">
                    <div class="vx-col sm:w-1/2">
                        <label> Start</label>
                        <datepicker v-model="contract.DateStart" placeholder="Effective Date (start)" :disabled="contract.StatusApproval ==1"></datepicker>
                    </div>
                    <div class="vx-col sm:w-1/2">
                        <label> End</label>
                        <datepicker v-model="contract.DateEnd" placeholder="Effective Date (end)" :disabled="contract.StatusApproval ==1"></datepicker>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Tax</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <multiselect
                            class="selectExample"
                            v-model="selectTax"
                            :options="optionTax"
                            :multiple="false"
                            :allow-empty="false"
                            :group-select="false"
                            :max-height="120"
                            :limit="3"
                            placeholder=" Type to search"
                            track-by="Rate"
                            label="Name"
                            :disabled="contract.StatusApproval ==1"
                        >

                        <template slot="singleLabel" slot-scope="dt">
                            <span class="option__desc">
                                <span class="option__title">{{ dt.option.Name }}</span>
                            </span>
                        </template>

                        <template slot="option" slot-scope="dt">
                            <div class="option__desc">
                                <span class="option__title">{{ dt.option.Name }}</span>
                            </div>
                        </template>

                    </multiselect>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/6 w-full">
                    <span>Line Items</span>
                </div>
                <div class="vx-col sm:w-5/6 w-full">
                    <vs-button size="small" class="mr-3 mb-2" @click="browseItem()" :disabled="contract.StatusApproval ==1">Browse</vs-button>
                </div>
            </div>
            <br>
            <div v-if="this.PriceModel != ''">Price Model : {{this.PriceModel}}</div>&nbsp;
            <div v-if="this.DiscountModel != ''">Discount Model : {{this.DiscountModel}}</div>&nbsp;
            <div v-if="this.DiscountUnit != ''">Discount Unit : {{this.DiscountUnit}}</div>&nbsp;
            <br>
            <hr>
            <br>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-table :data="itemsCode" stripe style="width:100%">
                        <template slot="thead">
                            <vs-th >No</vs-th>
                            <vs-th >Item</vs-th>
                            <vs-th>Qty Req.</vs-th>
                            <vs-th>Qty Adj.</vs-th>
                            <vs-th>Price</vs-th>
                            <vs-th>Discount Line</vs-th>
                            <vs-th>Total Line</vs-th>
                            <vs-th>Action</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(dt, index) in data" :class="{'text-danger': itemsLineTotal[index]==0}">
                                <vs-td style="align-item-right">{{index+1}}</vs-td>
                                <vs-td style="align-item-left">
                                    Code : {{itemsCode[index]}} <br>
                                    Name : {{itemsName[index]}}
                                </vs-td>
                                <vs-td>{{itemsQuantityReq[index]}} ({{itemsUom[index]}})</vs-td>
                                <vs-td>
                                    <vue-number-input
                                        inline
                                        controls
                                        :min="0"
                                        :step="1"
                                        size="small"
                                        v-model="itemsQuantityAdj[index]"
                                        :value="0"
                                        @keypress="isNumber($event)"
                                        :disabled="contract.StatusApproval ==1"
                                    />
                                </vs-td>
                                <vs-td>
                                    <vue-number-input
                                        inline
                                        controls
                                        :min="0"
                                        :step="1"
                                        size="small"
                                        v-model="itemsPriceCustom[index]"
                                        :value="0"
                                        @keypress="isNumber($event)"
                                        @change="changePrice()"
                                        :disabled="contract.StatusApproval ==1"
                                    />
                                </vs-td>
                                <vs-td>
                                    <vue-number-input
                                        inline
                                        controls
                                        :min="0"
                                        :step="1"
                                        size="small"
                                        v-model="itemsDiscountCustom[index]"
                                        :value="0"
                                        @keypress="isNumber($event)"
                                        @change="changePrice()"
                                        :disabled="contract.StatusApproval ==1"
                                    />
                                </vs-td>
                                <vs-td>
                                    Subtotal : {{ formatCurrency(itemsSubtotal[index]) }} <br>
                                    Tax : {{ formatCurrency(itemsTax[index]) }} <br>
                                    Line Total : {{ formatCurrency(itemsLineTotal[index]) }}
                                </vs-td>
                                <vs-td style="align-item-center">
                                    <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 text-danger stroke-current" class="ml-2" @click="deleteItemLine(index)" v-if="contract.StatusApproval !=1"/>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>

            <!-- total order = harga satuan dikali jumlah 
            total diskon = totalnya diskon
            total nett = total order - total diskon
            total pajak = total nett * pajak
            total bayar = total nett + total pajak -->

            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Order</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalBrutto" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Discount</span>
                    <feather-icon v-if="totalDiscountStep.length > 0"  title="Detail" icon="SearchIcon" svgClasses="w-5 h-5 text-info stroke-current" class="ml-2" @click="detailTotalDiscountStep()"/>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalDiscount" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Nett</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalNetto" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Tax</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalTax" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Charges</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input @keypress="isNumber($event)" @change="changeCharge()" class="vs-inputx vs-input--input" style="text-align:right" v-model="contract.SubtotalCharge"/>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalBilling" disabled />
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col sm:w-6/12 w-full" v-if="contract.StatusApproval !=1 ">
                    <vs-button v-if="calculated" class="mr-3 mb-2" v-on:click="SubmitForm()">Submit</vs-button>
                    <vs-button v-if="calculated != true" class="mr-3 mb-2" v-on:click="CalculateSOLine()">Calculate</vs-button>
                    <vs-button v-if="update" color="warning" class="mr-3 mb-2" @click="ResetForm()">Reset</vs-button>
                    <vs-button v-if="update" color="danger" class="mr-3 mb-2" @click="CancelForm()">Cancel</vs-button>
                </div>
            </div>
        </div>
        
        <vs-popup fullscreen title="Browse Item Available" :active.sync="optionItemAvailableShow">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-dropdown vs-trigger-click class="cursor-pointer">
                        <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                            <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                        </div>
                        <vs-dropdown-menu>                
                            <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                <span>{{item}}</span>
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>
                <div class="mx-auto"></div>
                <div class="vx-col sm:w-1/3 w-full">
                    <div class="flex flex-wrap items-center justify-between">
                        <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name, or UOM " />
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-table :data="optionItemAvailable" stripe style="width:100%">
                        <template slot="thead">
                            <vs-th>Code</vs-th>
                            <vs-th>Name</vs-th>
                            <vs-th>UOM</vs-th>
                            <vs-th>Action</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(dt, index) in data">
                                <vs-td>{{dt.code}}</vs-td>
                                <vs-td style="align-item-center">{{dt.name}}</vs-td>                                
                                <vs-td>({{dt.uom}})</vs-td>
                                <vs-td style="align-item-center">
                                    <feather-icon title="Add" icon="PlusIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="addItemLine(dt)"/>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                    <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                </div>
            </div>
        </vs-popup>
            
        <vs-popup fullscreen title="Browse Customer" :active.sync="optionCustomerShow">
            <div class="vx-row mb-6">
                <div class="vx-col sm:w-1/3 w-full">
                    <vs-dropdown vs-trigger-click class="cursor-pointer">
                        <div class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium">
                            <span class="mr-2">{{this.dataEnd==0 ? 0: (this.limitShow * (this.isActive - 1) + 1)}} - {{this.limitShow * (this.isActive - 1) + dataEnd}} of {{this.recordsTotal}}</span>
                            <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                        </div>
                        <vs-dropdown-menu>                
                            <vs-dropdown-item v-for="item in limits" v-bind:key="item" @click="changeLimit(item)">
                                <span>{{item}}</span>
                            </vs-dropdown-item>
                        </vs-dropdown-menu>
                    </vs-dropdown>
                </div>
                <div class="mx-auto"></div>
                <div class="vx-col sm:w-1/3 w-full">
                    <div class="flex flex-wrap items-center justify-between">
                        <vs-input class="mb-4 md:mb-0 mr-4 w-full" v-model="search" placeholder="Search by : Code, Name or City " />
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-table :data="optionCustomer" stripe style="width:100%">
                        <template slot="thead">
                            <vs-th>Code</vs-th>
                            <vs-th>Name</vs-th>
                            <vs-th>Address</vs-th>
                            <vs-th>Category</vs-th>
                            <vs-th>Action</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(dt, index) in data">
                                <vs-td>{{dt.code}}</vs-td>
                                <vs-td>{{dt.name}}</vs-td>                                
                                <vs-td>
                                    City : {{dt.city}} <br>
                                    Address : {{dt.address}}
                                </vs-td>
                                <vs-td>{{dt.customer_category_name}}</vs-td>
                                <vs-td style="align-item-center">
                                    <feather-icon title="Add" icon="CheckIcon" svgClasses="w-5 h-5 text-success stroke-current" class="ml-2" @click="selectCustomer(dt)"/>
                                </vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                    <vs-pagination style="padding-top:5px" :total="maxPage" v-model="currentPage" :prev-icon="$vs.rtl ? 'arrow_forward' : 'arrow_back'" :next-icon="$vs.rtl ? 'arrow_back' : 'arrow_forward'"/>
                </div>
            </div>
        </vs-popup>

        <div v-if="this.detailStepShow" class="vx-col sm:w-1/2 w-full mb-base">
            <div v-if="(typeof pricingStep[indexStep]) != 'undefined'">
                <div class="vx-row mb-6">          
                    <h3>Price Engine - Price (Model : Step Ladder)</h3>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-table :data="pricingStep[indexStep]" stripe style="width:100%">
                            <template slot="thead">
                                <vs-th>Step</vs-th>
                                <vs-th>Qty</vs-th>
                                <vs-th>Price</vs-th>
                                <vs-th>Qty x Price</vs-th>
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="index" v-for="(dt, index) in data">
                                    <vs-td style="text-align:right">{{ index+1 }}</vs-td>
                                    <vs-td style="text-align:right">{{ dt.qty }}</vs-td>
                                    <vs-td style="text-align:right">{{ formatCurrency(dt.price) }}</vs-td>
                                    <vs-td style="text-align:right">{{ formatCurrency(dt.qty * dt.price) }}</vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>
            </div>
            <div v-if="(typeof discountStep[indexStep]) != 'undefined'">
                <div class="vx-row mb-6">                
                    <h3>Price Engine - Discount (Model : Step Ladder - Unit : Line)</h3>
                </div>
                <div class="vx-row mb-6">
                    <div class="vx-col w-full">
                        <vs-table :data="discountStep[indexStep]" stripe style="width:100%">
                            <template slot="thead">
                                <vs-th>Step</vs-th>
                                <vs-th>Nominal</vs-th>
                                <vs-th>Percentage</vs-th>
                                <vs-th>Amount</vs-th>                                
                            </template>

                            <template slot-scope="{data}">
                                <vs-tr :key="index" v-for="(dt, index) in data">
                                    <vs-td style="text-align:right">{{ index+1 }}</vs-td>
                                    <vs-td style="text-align:right">{{ formatCurrency(dt.nominal) }}</vs-td>
                                    <vs-td style="text-align:right">{{ dt.percentage }}%</vs-td>
                                    <vs-td style="text-align:right">{{ formatCurrency(dt.amount) }}</vs-td>
                                </vs-tr>
                            </template>
                        </vs-table>
                    </div>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Sub Total Line</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsSubtotalEngine[indexStep])" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Discount Line</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsDiscountEngine[indexStep])" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Tax Line</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsTaxEngine[indexStep])" disabled />
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Line</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" :value="formatCurrency(itemsLineTotalEngine[indexStep])" disabled />
                </div>
            </div>
        </div>

        <div v-if="this.detailTotalDiscountStepShow" class="vx-col sm:w-1/2 w-full mb-base">
            <div class="vx-row mb-6">                
                <h3>Price Engine - Discount (Model : Step Ladder - Unit : Total)</h3>
            </div>
            <div class="vx-row mb-6">
                <div class="vx-col w-full">
                    <vs-table :data="totalDiscountStep" stripe style="width:100%">
                        <template slot="thead">
                            <vs-th>Step</vs-th>
                            <vs-th>Nominal</vs-th>
                            <vs-th>Percentage</vs-th>
                            <vs-th>Amount</vs-th>
                        </template>

                        <template slot-scope="{data}">
                            <vs-tr :key="index" v-for="(dt, index) in data">
                                <vs-td style="text-align:right">{{ index+1 }}</vs-td>
                                <vs-td style="text-align:right">{{ formatCurrency(dt.nominal) }}</vs-td>
                                <vs-td style="text-align:right">{{ formatCurrency(dt.percentage) }}%</vs-td>
                                <vs-td style="text-align:right">{{ formatCurrency(dt.amount) }}</vs-td>
                            </vs-tr>
                        </template>
                    </vs-table>
                </div>
            </div>
            <div class="vx-row mb-6" style="width:100%; font-size:10pt;">
                <div class="vx-col sm:w-4/5 w-full" style="text-align:right">
                    <span>Total Discount</span>
                </div>
                <div class="vx-col sm:w-1/5 w-full">
                    <input class="vs-inputx vs-input--input" style="text-align:right" v-model="totalDiscount" disabled />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import VueNumberInput from "@chenfengyuan/vue-number-input";
// import moment from 'moment'

export default {
    props: ["territoryCode", "territoryId", "selectedContract", "optionTerritory"],
    components: {
        Datepicker,
        VueNumberInput,
    },
    mounted(){
        // console.log("userLogin : ", this.$userLogin);
        // console.log("IKI LOOH", this.selectedContract);
        if(this.selectedContract && this.selectedContract.Code) {
            this.resetContract = Object.assign({}, this.selectedContract);
            this.contract = this.selectedContract;
            this.update = true;
            // this.external = true;
            this.external = false; // sementara dibikin true, belom ada object saleschannel di api SO
            this.selectTerritory = {
                code: this.selectedContract.TerritoryCode,
                name: this.selectedContract.TerritoryName
            };
            this.selectTax = {
                Name: "",
                Rate: this.selectedContract.TaxRate
            };

            for (var i = 0; i < this.contract.ContractItem.length; i++) {
                this.itemsCode.push(this.contract.ContractItem[i].ItemCode);
                this.itemsName.push(this.contract.ContractItem[i].ItemName);
                this.itemsUom.push(this.contract.ContractItem[i].ItemUnit);
                this.itemsQuantityReq.push(this.contract.ContractItem[i].Quantity);
                this.itemsQuantityAdj.push(0);

                this.itemsPrice.push(this.contract.ContractItem[i].Price);
                this.itemsPriceCustom.push(this.contract.ContractItem[i].Price);

                this.itemsSubtotal.push(this.contract.ContractItem[i].Subtotal);
                this.itemsDiscount.push(this.contract.ContractItem[i].Discount);
                this.itemsDiscountCustom.push(this.contract.ContractItem[i].Discount);
                this.itemsTax.push(this.contract.ContractItem[i].TaxAmount);
                this.itemsLineTotal.push(this.contract.ContractItem[i].Total);

                this.itemsSubtotalEngine.push(this.contract.ContractItem[i].Subtotal);
                this.itemsDiscountEngine.push(this.contract.ContractItem[i].Discount);
                this.itemsTaxEngine.push(this.contract.ContractItem[i].TaxAmount);
                this.itemsLineTotalEngine.push(this.contract.ContractItem[i].Total);
            }

            this.totalBrutto = this.formatCurrency(this.contract.Subtotal); // total order = harga satuan dikali jumlah 
            this.totalDiscount = this.formatCurrency(this.contract.Discount); // total diskon = totalnya diskon
            this.totalNetto = this.formatCurrency(this.contract.Subtotal - this.contract.Discount); // total nett = total order - total diskon
            this.totalTax = this.formatCurrency(this.contract.TaxAmount); // total pajak = total nett * pajak
            this.totalBilling = this.formatCurrency(this.contract.Total); // total bayar = total nett + total pajak

            // console.log("iki loh : this.contract", this.contract);
        } else {
            this.selectTerritory = {
                code: this.territoryCode,
                name: ''
            };
           this.setDefaultDate();
           this.resetContract = Object.assign({}, this.contract);
        }

        this.getOptionTax();
    },
    computed: {
        charactersLeft() {
            var char = this.contract.Notes.length,
                limit = 255;

            return "counter " + (limit - char);
        },
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                if(this.optionItemAvailableShow) {
                    this.getOptionItemAvailable(page);
                }
                if(this.optionCustomerShow) {
                    this.getOptionCustomer(page);
                }
                return page;
            }
        },
    },
    watch: {
        search: function() {
            if(this.optionItemAvailableShow) {
                this.getOptionItemAvailable();
            }
            if(this.optionCustomerShow) {
                this.getOptionCustomer();
            }
        },
        itemsQuantityReq: function() {
            this.calculated = false;
        },
        itemsQuantityAdj: function() {
            this.calculated = false;
        },
        selectTax: function() {
            this.calculated = false;
        },
        territoryCode: function () {
            this.selectTerritory = {
                code: this.territoryCode,
                name: ''
            };
        }
    },
    data: () => ({
        hour: "",
        minute: "",

        update: false,
        calculated: false,
        external: false,

        selectTerritory:{},
        selectTax:{
            Name: "",
            Rate: 0
        },

        resetContract: {},
        contract: {
            Code: "",
            CustomerID: 0,
            CustomerName: "",
            CustomerCode: "",
            TerritorryID: 0,
            TerritorryCode: "",
            CompanyID: 0,
            CompanyCode: "",
            SalesChannelID: 0,
            SalesChannelCode: "",
            SalesChannelName: "",
            Date: "",
            DateStart: "",
            DateEnd: "",
            Notes: "",
            SubtotalCharge: 0,

            Daily: 0,
            Monthly: 0,
            OnceInDays: 0,
            OnceInMonths: 0,
        },

        itemsCode: [],
        itemsName: [],
        itemsUom: [],
        itemsQuantityReq: [],
        itemsQuantityAdj: [],

        itemsPrice: [],
        itemsPriceCustom: [],
        itemsSubtotal: [],
        itemsDiscount: [],
        itemsDiscountCustom: [],
        itemsTax: [],
        itemsLineTotal: [],
        
        itemsSubtotalEngine: [],
        itemsDiscountEngine: [],
        itemsTaxEngine: [],
        itemsLineTotalEngine: [],

        pricingStep: [],
        discountStep: [],
        totalDiscountStep: [],
        
        totalBrutto: 0,
        totalDiscount: 0,
        totalNetto: 0,
        totalTax: 0,
        totalBilling: 0,

        optionCustomer: [{}],
        optionItemAvailable: [{}],
        optionTax: [{}],

        optionItemAvailableShow: false,
        optionCustomerShow: false,
        detailStepShow: false,
        detailTotalDiscountStepShow: false,

        indexStep: 0,

        PriceModel: '',
        DiscountModel: '',
        DiscountUnit: '',

        limits: [10, 25, 50, 100, "All"],
        limitShow: 10,
        drawData: 0,
        isActive: 1,
        search: "",
        recordsTotal: 0,
        maxPage: 2,
        dataEnd: 0,
    }),
    methods: {
        scrollToTop() {
            window.scrollTo(0,0);
        },
        formatCurrency(nominal) {
            if(nominal) {
                nominal = (nominal/1).toFixed(2).replace('.', ',');
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },
        isNumber: function(evt) {
            evt = (evt) ? evt : window.event;
            var charCode = (evt.which) ? evt.which : evt.keyCode;
            if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
                evt.preventDefault();
            } else {
                return true;
            }
        },
        containsKey(obj, key ) {
            return Object.keys(obj).includes(key);
        },
        ResetForm() {
            this.calculated = false;
            this.contract = Object.assign({}, this.resetContract);
            this.itemsCode = [];
            this.itemsName= [];
            this.itemsUom= [];
            this.itemsQuantityReq = [];
            this.itemsQuantityAdj = [];

            this.itemsPrice = [];
            this.itemsPriceCustom = [];

            this.itemsSubtotal = [];
            this.itemsDiscount = [];
            this.itemsDiscountCustom = [];
            this.itemsTax = [];
            this.itemsLineTotal = [];

            this.itemsSubtotalEngine = [];
            this.itemsDiscountEngine = [];
            this.itemsTaxEngine = [];
            this.itemsLineTotalEngine = [];
            for (var i = 0; i < this.contract.ContractItem.length; i++) {
                this.itemsCode.push(this.contract.ContractItem[i].ItemCode);
                this.itemsName.push(this.contract.ContractItem[i].ItemName);
                this.itemsUom.push(this.contract.ContractItem[i].ItemUnit);
                this.itemsQuantityReq.push(this.contract.ContractItem[i].Quantity);
                this.itemsQuantityAdj.push(0);

                this.itemsPrice.push(this.contract.ContractItem[i].Price);
                this.itemsPriceCustom.push(this.contract.ContractItem[i].Price);

                this.itemsSubtotal.push(this.contract.ContractItem[i].Subtotal);
                this.itemsDiscount.push(this.contract.ContractItem[i].Discount);
                this.itemsDiscountCustom.push(this.contract.ContractItem[i].Discount);
                this.itemsTax.push(this.contract.ContractItem[i].TaxAmount);
                this.itemsLineTotal.push(this.contract.ContractItem[i].Total);

                this.itemsSubtotalEngine.push(this.contract.ContractItem[i].Subtotal);
                this.itemsDiscountEngine.push(this.contract.ContractItem[i].Discount);
                this.itemsTaxEngine.push(this.contract.ContractItem[i].TaxAmount);
                this.itemsLineTotalEngine.push(this.contract.ContractItem[i].Total);
            }
            this.totalBrutto = this.formatCurrency(this.contract.Subtotal); // total order = harga satuan dikali jumlah 
            this.totalDiscount = this.formatCurrency(this.contract.Discount); // total diskon = totalnya diskon
            this.totalNetto = this.formatCurrency(this.contract.Subtotal - this.contract.Discount); // total nett = total order - total diskon
            this.totalTax = this.formatCurrency(this.contract.TaxAmount); // total pajak = total nett * pajak
            this.totalBilling = this.formatCurrency(this.contract.Total); // total bayar = total nett + total pajak
        },
        CancelForm() {
            this.calculated = false;
            this.resetContract = {
                Code: "",
                CustomerID: 0,
                CustomerName: "",
                CustomerCode: "",
                TerritorryID: 0,
                TerritorryCode: "",
                CompanyID: 0,
                CompanyCode: "",
                SalesChannelID: 0,
                SalesChannelCode: "",
                SalesChannelName: "",
                Date: "",
                DateStart: "",
                DateEnd: "",
                Notes: "",
                SubtotalCharge: 0,

                Daily: 0,
                Monthly: 0,
                OnceInDays: 0,
                OnceInMonths: 0,
            };
            this.contract = Object.assign({}, this.resetContract);
            this.selectTerritory = {};
            this.selectTax = {
                Name: "",
                Rate: 0
            };
            this.calculated = false;
            this.update = false;
            this.external = false;            

            this.itemsCode = [];
            this.itemsName = [];
            this.itemsUom = [];
            this.itemsQuantityReq = [];
            this.itemsQuantityAdj = [];

            this.itemsPrice = [];
            this.itemsPriceCustom = [];
            this.itemsSubtotal = [];
            this.itemsDiscount = [];
            this.itemsDiscountCustom = [];
            this.itemsTax = [];
            this.itemsLineTotal = [];
            
            this.itemsSubtotalEngine = [];
            this.itemsDiscountEngine = [];
            this.itemsTaxEngine = [];
            this.itemsLineTotalEngine = [];

            this.pricingStep = [];
            this.discountStep = [];
            this.totalDiscountStep = [];

            this.totalBrutto = 0;
            this.totalDiscount = 0;
            this.totalNetto = 0;
            this.totalTax = 0;
            this.totalBilling = 0;

            this.optionItemAvailableShow = false;
            this.optionCustomerShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;

            this.indexStep = 0;

            this.PriceModel = '';
            this.DiscountModel = '';
            this.DiscountUnit = '';            

            this.$emit("selectContract", {});
        },
        browseItem() {
            this.search = "";
            this.indexStep = 0;
            this.optionItemAvailableShow = true;
            this.optionCustomerShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.getOptionItemAvailable();
            this.scrollToTop();
        },
        browseCustomer() {
            this.search = "";
            this.indexStep = 0;
            this.optionCustomerShow = true;
            this.optionItemAvailableShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            this.getOptionCustomer();
            this.scrollToTop();
        },
        detailStep(index) {
            this.search = "";
            this.indexStep = index;
            this.detailStepShow = true;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.detailTotalDiscountStepShow = false;
            this.scrollToTop();
        },
        detailTotalDiscountStep() {
            this.search = "";
            this.indexStep = 0;
            this.detailTotalDiscountStepShow = true;
            this.optionCustomerShow = false;
            this.optionItemAvailableShow = false;
            this.detailStepShow = false;
            this.scrollToTop();
        },
        addItemLine(item) {
            this.calculated = false;
            if(this.external == false) {
                if(this.itemsCode.indexOf(item.code) > -1 && this.itemsUom.indexOf(item.uom) > -1) {
                    this.$vs.notify({
                        title: "Warning",
                        text: "Duplicate item detected ",
                        color: "warning",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                } else {
                    this.itemsCode.push(item.code);
                    this.itemsName.push(item.name);
                    this.itemsUom.push(item.uom);
                    this.itemsQuantityReq.push(0);
                    this.itemsQuantityAdj.push(0);
                    this.itemsPrice.push(0);
                    this.itemsPriceCustom.push(0);
                    this.itemsSubtotal.push(0);
                    this.itemsDiscount.push(0);
                    this.itemsDiscountCustom.push(0);
                    this.itemsTax.push(0);
                    this.itemsLineTotal.push(0);
                    
                    this.itemsSubtotalEngine.push(0);
                    this.itemsDiscountEngine.push(0);
                    this.itemsTaxEngine.push(0);
                    this.itemsLineTotalEngine.push(0);

                    this.$vs.notify({
                        title: "Added",
                        text: item.name + "("+item.uom+ ")",
                        color: "primary",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to add or remove external Sales Order item line ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        selectCustomer(customer) {
            this.optionCustomerShow = false;
            this.calculated = false;
            if(this.external == false) {
                // hanya update value yg penting2 aja wkwk
                this.contract.CustomerCode = customer.code;
                this.contract.CustomerName = customer.name;
                this.contract.CustomerID = customer.id;
                
                // this.getTime(this.contract.CustomerID); // ini utk set default time based on custsomer work time

                this.$vs.notify({
                    title: "Selected",
                    text: customer.name + "("+customer.code+ ")",
                    color: "primary",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to change external Sales Order Customer ",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        deleteItemLine(index) {
            this.calculated = false;
            if(this.external == false) {
                this.$vs.notify({
                    title: "Deleted",
                    text: this.itemsName[index] + "("+this.itemsUom[index]+ ")",
                    color: "primary",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });

                this.itemsCode.splice(index, 1);
                this.itemsName.splice(index, 1);
                this.itemsUom.splice(index, 1);
                this.itemsQuantityReq.splice(index, 1);
                this.itemsQuantityAdj.splice(index, 1);
                
                this.itemsPrice.splice(index, 1);
                this.itemsPriceCustom.splice(index, 1);
                this.itemsSubtotal.splice(index, 1);
                this.itemsDiscount.splice(index, 1);
                this.itemsDiscountCustom.splice(index, 1);
                this.itemsTax.splice(index, 1);
                this.itemsLineTotal.splice(index, 1);
                
                this.itemsSubtotalEngine.splice(index, 1);
                this.itemsDiscountEngine.splice(index, 1);
                this.itemsTaxEngine.splice(index, 1);
                this.itemsLineTotalEngine.splice(index, 1);

                this.pricingStep.splice(index, 1);
                this.discountStep.splice(index, 1);
                this.totalDiscountStep.splice(index, 1);
            } else {
                this.$vs.notify({
                    title: "Warning",
                    text: "You're not allowed to add or remove external Sales Order item line",
                    color: "warning",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
            }
        },
        changeLimit(limit) {
            this.limitShow = limit;
            if(this.optionItemAvailableShow) {
                this.getOptionItemAvailable(this.isActive);
            }
            if(this.optionCustomerShow) {
                this.getOptionCustomer(this.isActive);
            }
        },
        changePrice() {
            this.calculated = false;
        },
        changeCharge() {
            this.calculated = false;
        },
        getOptionItemAvailable(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/item-available", {
                params: {
                    territory_code : this.selectTerritoryCode,
                    search: this.search,
                    limit: this.limitShow,
                    offset: this.limitShow * (page - 1)
                }
            }).then(resp => {
                // console.log(resp.data);
                if (resp.code == 200) {
                    this.optionItemAvailable = resp.data.ItemsAvailable;
                    this.recordsTotal = resp.data.RecordsTotal;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.ResultsTotal;
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Item Available",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionCustomer(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/master/customer", {
                params: {
                    search: this.search,
                    length: this.limitShow,
                    page: page,
                    type: "End Customer",
                    territory_id: this.territoryId,
                }
            }).then(resp => {
                // console.log("option customer", resp);
                if (resp.code == 200) {
                    this.optionCustomer = resp.data.records;
                    this.recordsTotal = resp.data.total_record;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.total_record_search;
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Customer",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        getOptionTax(page = 1) {
            this.isActive = page;
            this.$vs.loading();

            this.$http.get("/api/v1/master/tax", {
                params: {
                    search: this.search,
                    length: this.limitShow,
                    page: page,
                }
            }).then(resp => {
                if (resp.code == 200) {
                    this.optionTax = resp.data.records;
                    this.recordsTotal = resp.data.total_record;
                    this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                    this.dataEnd = resp.data.total_record_search;

                    for (let index = 0; index < this.optionTax.length; index++) {
                        if(this.optionTax[index].Rate == this.selectTax.Rate) {
                            this.selectTax.Name = this.optionTax[index].Name;
                            break;
                        } else if(this.optionTax[index].IsDefault == 1 && this.selectTax.Name == "" && this.selectTax.Rate == 0) {
                            this.selectTax = {
                                Name: this.optionTax[index].Name,
                                Rate: this.optionTax[index].Rate
                            };
                            break;
                        }   
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to get Tax option",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        CalculateSOLine() {
            if(this.contract.CustomerCode == "" || this.contract.CustomerCode == 0 || this.contract.CustomerCode == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Customer first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.contract.Date == "" || this.contract.Date == 0 || this.contract.Date == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.contract.DateStart == "" || this.contract.DateStart == 0 || this.contract.DateStart == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Date (Start) first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.contract.DateEnd == "" || this.contract.DateEnd == 0 || this.contract.DateEnd == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Date (End) first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            this.search = "";
            this.indexStep = 0;
            this.optionItemAvailableShow = false;
            this.optionCustomerShow = false;
            this.detailStepShow = false;
            this.detailTotalDiscountStepShow = false;
            // this.$vs.loading();
            this.$http
            .post("/api/v1/sales-order/calculate", {
                sales_channel_code: this.contract.SalesChannelCode,
                territory_code: this.territoryCode,
                company_code: this.contract.CompanyCode,
                customer_code: this.contract.CustomerCode,
                items_code: this.itemsCode,
                items_name: this.itemsName,
                items_uom: this.itemsUom,
                items_quantity_request: this.itemsQuantityReq,
                items_quantity_adjusted: this.itemsQuantityAdj,
                items_price: this.itemsPriceCustom,
                items_discount: this.itemsDiscountCustom,
                tax_rate: this.selectTax.Rate,
                subtotal_charge: parseFloat(this.contract.SubtotalCharge),
            })
            .then(resp => {
                // console.log(resp);
                if (resp.code == 200) {
                    this.$vs.notify({
                        title: "Success",
                        text: "",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    // console.log(resp);
                    // this.itemsQtyStep = [];
                    // this.itemsPriceStep = [];
                    // this.itemsDiscountStep = [];

                    this.itemsPrice = [];
                    this.itemsPriceCustom = [];
                    this.itemsSubtotal = [];
                    this.itemsTax = [];
                    this.itemsLineTotal = [];
                    
                    this.itemsSubtotalEngine = [];
                    this.itemsTaxEngine = [];
                    this.itemsLineTotalEngine = [];

                    this.pricingStep = [];
                    this.discountStep = [];
                    this.totalDiscountStep = [];
                    
                    this.discountStepEngine = [];
                    this.totalDiscountStepEngine = [];

                    var sales_order = resp.data;
                    this.totalBrutto = this.formatCurrency(sales_order.total_order); // total order = harga satuan dikali jumlah 
                    this.totalDiscount = this.formatCurrency(sales_order.total_discount); // total diskon = totalnya diskon
                    this.totalNetto = this.formatCurrency(sales_order.total_netto); // total nett = total order - total diskon
                    this.totalTax = this.formatCurrency(sales_order.total_tax); // total pajak = total nett * pajak
                    this.totalBilling = this.formatCurrency(sales_order.total_billing); // total bayar = total nett + total pajak
                    this.contract.SubtotalCharge = sales_order.total_charge

                    this.calculated = true;
                    for (var i = 0; i < sales_order.pricing.length; i++) {
                        this.itemsPrice[i] = sales_order.pricing[i].price;
                        this.itemsSubtotal[i] = sales_order.pricing[i].sub_total;
                        this.itemsTax[i] = sales_order.pricing[i].tax;

                        this.itemsSubtotalEngine[i] = sales_order.pricing[i].sub_total_engine;
                        this.itemsTaxEngine[i] = sales_order.pricing[i].tax_engine;

                        if(sales_order.pricing[i].price <= 0) {
                            this.calculated = false;
                        }

                        if(sales_order.pricing[i].sub_total <= 0 && sales_order.pricing[i].sub_total_engine <= 0) {
                            this.calculated = false;
                        }
                        
                        // PRICING
                        if(sales_order.price_model == "Step Ladder" && sales_order.pricing[i].steps.length > 0) {
                            this.pricingStep[i] = sales_order.pricing[i].steps;
                        } else {
                            this.pricingStep = [];
                        }
                        if(sales_order.pricing[i].custom_price == 0) {
                            this.itemsPriceCustom[i] = sales_order.pricing[i].price;
                        } else {
                            this.itemsPriceCustom[i] = sales_order.pricing[i].custom_price;   
                        }

                        // DISCOUNT
                        var lineTotal = 0;
                        var lineTotalEngine = 0;
                        if(i < sales_order.discount_line.length) {
                            if(sales_order.discount_model == "Step Ladder" && this.containsKey(sales_order.discount_line[i], 'steps') && sales_order.discount_line[i].steps.length > 0) {
                                this.discountStep[i] = sales_order.discount_line[i].steps;
                                this.discountStepEngine[i] = sales_order.discount_line_engine[i].steps;
                            } else {
                                this.discountStep = [];
                                this.discountStepEngine = [];
                            }
                            this.itemsDiscount[i] = sales_order.discount_line[i].amount;
                            this.itemsDiscountCustom[i] = sales_order.discount_line[i].amount;
                            this.itemsDiscountEngine[i] = sales_order.discount_line_engine[i].amount;

                            lineTotal = sales_order.pricing[i].sub_total - sales_order.discount_line[i].amount + sales_order.pricing[i].tax;
                            lineTotalEngine = sales_order.pricing[i].sub_total_engine - sales_order.discount_line_engine[i].amount + sales_order.pricing[i].tax_engine;
                        } else {
                            this.discountStep = [];
                            this.discountStepEngine = [];
                            this.itemsDiscount[i] = 0;
                            this.itemsDiscountCustom[i] = 0;
                            this.itemsDiscountEngine[i] = 0;
                            lineTotal = sales_order.pricing[i].sub_total - 0 + sales_order.pricing[i].tax;
                            lineTotalEngine = sales_order.pricing[i].sub_total_engine - 0 + sales_order.pricing[i].tax_engine;
                        }

                        this.itemsLineTotal[i] = lineTotal;
                        this.itemsLineTotalEngine[i] = lineTotalEngine;
                    }

                    // discount unit == final
                    if(sales_order.discount_unit == "Total" && sales_order.discount_model == "Step Ladder" && this.containsKey(sales_order.discount, 'steps') && sales_order.discount.steps.length > 0) {
                        this.totalDiscountStep = sales_order.discount.steps;
                        this.totalDiscountStepEngine = sales_order.discount_engine.steps;
                    }

                    this.PriceModel = sales_order.price_model;
                    this.DiscountModel = sales_order.discount_model;
                    this.DiscountUnit = sales_order.discount_unit;

                    if(sales_order.total_billing <= 0) {
                        this.calculated = false;
                    }
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: "Failed to calculate Sales Order",
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    this.calculated = false;
                }
                // this.$vs.loading.close();
            });
        },
        SubmitForm() {
            if(this.contract.Date == "" || this.contract.Date == 0 || this.contract.Date == null) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Date first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.contract.DateStart == "" || this.contract.DateStart == 0 || this.contract.DateStart == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Date (Start) first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            if(this.contract.DateEnd == "" || this.contract.DateEnd == 0 || this.contract.DateEnd == null ) {
                this.$vs.notify({
                    title: "Error",
                    text: "Please select Date (End) first",
                    color: "danger",
                    position: "top-right",
                    iconPack: "feather",
                    icon: "icon-check"
                });
                return true;
            }

            // this.$vs.loading();
            this.$http
            .post("/api/v1/closed-contract/store", {
                contract_code: this.contract.Code,
                sales_channel_code: this.contract.SalesChannelCode,
                territory_code: this.territoryCode,
                company_code: this.contract.CompanyCode,
                customer_code: this.contract.CustomerCode,
                items_code: this.itemsCode,
                items_name: this.itemsName,
                items_uom: this.itemsUom,
                items_quantity_request: this.itemsQuantityReq,
                items_quantity_adjusted: this.itemsQuantityAdj,
                items_price: this.itemsPriceCustom,
                items_discount: this.itemsDiscountCustom,
                date: this.contract.Date,
                date_start: this.contract.DateStart,
                date_end: this.contract.DateEnd,
                tax_rate: this.selectTax.Rate,
                notes: this.contract.Notes,
                subtotal_charge: this.contract.SubtotalCharge,
            })
            .then(resp => {
                // console.log(resp);
                if (resp.code == 200) {
                    this.$vs.notify({
                        title: "Success",
                        text: "Contract has been saved",
                        color: "success",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                    this.CancelForm();
                    this.setDefaultDate();
                    this.getOptionTax();
                } else {
                    this.$vs.notify({
                        title: "Error",
                        text: resp.message,
                        color: "danger",
                        position: "top-right",
                        iconPack: "feather",
                        icon: "icon-check"
                    });
                }
                this.$vs.loading.close();
            });
        },
        setDefaultDate() {
            var day = new Date();
            var nextWeek = new Date();
            var nextDay = new Date(day);
            nextDay.setDate(day.getDate() + 1); 
            nextWeek.setDate(day.getDate() + 7); 

            this.contract.Date = day
            this.contract.DateStart = day
            this.contract.DateEnd = nextWeek
        },
        getTime(id) {
            this.$vs.loading();
            this.$http.get("/api/v1/master/customer/work-time/"+id).then(resp => {
                if (resp.code == 200) {
                   var day = new Date();
                    var nextDay = new Date(day);
                    nextDay.setDate(day.getDate() + 1);
                    nextDay.setHours(resp.data[0])
                    nextDay.setMinutes(resp.data[1])
                    this.contract.DateEnd = nextDay
                }
                this.$vs.loading.close();
            });
        },
    },
};
</script>
