<template>
    <div>
        <div class="vx-row mb-12">
            <div class="vx-col sm:w-1/1 w-full mb-base" v-if="!this.selectedContract.Code || this.selectedContract.StatusApproval != 1">
                <center>Please choose contract first, and make sure your contract approved by Manager</center>
            </div>
            <div class="core vx-col md:w-1/1 w-full mb-base" v-if="this.selectedContract && this.selectedContract.Code  && this.selectedContract.StatusApproval == 1">
                <vs-table :data="dataContractTemplate" stripe border>
                    <template slot="thead">
                        <vs-th>Action</vs-th>
                        <vs-th>SO Code</vs-th>
                        <vs-th>Items</vs-th>
                        <vs-th>Date</vs-th>
                        <vs-th>Total</vs-th>
                    </template>

                    <template slot-scope="{data}">
                        <vs-tr :key="indexTemplate" v-for="(dt, indexTemplate) in data">
                            <vs-td>
                                <feather-icon title="Edit" icon="EditIcon" svgClasses="w-5 h-5 hover:text-primary stroke-current" class="ml-2" @click="clickEdit(dt)" v-if="dt.ContractTemplateOrder.length == 0"/>
                                <feather-icon title="Delete" icon="TrashIcon" svgClasses="w-5 h-5 hover:text-danger stroke-current" class="ml-2" @click="clickDelete(dt)" v-if="dt.ContractTemplateOrder.length == 0"/>
                                <feather-icon title="Order" icon="CheckIcon" svgClasses="w-5 h-5 hover:text-success stroke-current" class="ml-2" @click="clickOrder(dt)" v-if="dt.ContractTemplateOrder.length == 0"/>
                            </vs-td>
                            <vs-td>
                                <vs-row :key="idxSO" v-for="(so, idxSO) in dt.ContractTemplateOrder">
                                    {{so.SalesOrderCode}}
                                </vs-row>
                            </vs-td>
                            <vs-td>
                                <vs-row :key="indexItem" v-for="(item, indexItem) in dt.ContractTemplateLine">
                                    <vs-col vs-type="flex" vs-justify="left" vs-align="right" vs-w="3">
                                        {{item.ItemCode}}
                                    </vs-col>
                                    <vs-col vs-type="flex" vs-justify="left" vs-align="right" vs-w="7">
                                        {{item.ItemName}} 
                                    </vs-col>
                                    <vs-col vs-type="flex" vs-justify="right" vs-align="center" vs-w="2">
                                        {{item.Quantity}} {{item.ItemUnit}}
                                    </vs-col>
                                </vs-row>
                            </vs-td>
                            <vs-td>
                                {{ dateFormat(dt.DueDate) }}
                            </vs-td>
                            <vs-td align="right">
                                {{ formatCurrency(dt.Total) }}
                            </vs-td>
                        </vs-tr>
                    </template>
                </vs-table>
            </div>
        </div>
    </div>
</template>


<script>
// import detail from "../component/detail_selected_so.vue";
import moment from 'moment'

export default {
    components: {
        // detail
    },
    props: ["territoryCode", "territoryId", "selectedContract", "optionTerritory"],
    mounted(){
        this.dataContractTemplate = this.selectedContract.ContractTemplate;
    },
    data() {
        return {
            dataContractTemplate:[],
            dataContract: [{
                ContractLine: [{}],
                Customer: {
                    Code: "",
                    Name: "",
                    Address: "",
                    Phone: "",
                    CustomerCategory: {
                        Name: ""
                    }
                },
                Company: {
                    Code: "",
                    Name: "",
                },
                SalesChannel: {
                    Code: "",
                    Name: ""
                },
                BillAddress: {
                    Address: "",
                    City: "",
                    Country: "",
                },
                ShipAddress: {
                    Address: "",
                    City: "",
                    Country: "",
                },
                Salesman: {
                    Name: "",
                },
                PaymentTerm: {
                    Name: "",
                }
            }],
            contractCode: "",

            limits: [10, 25, 50, 100, "All"],
            limitShow: 10,
            drawData: 0,
            isActive: 1,
            search: "",
            detailShow: "core vx-col md:w-1/2 w-full mb-base ",
            detailHide: "core vx-col md:w-1/1 w-full mb-base ",
            detail: false,
            recordsTotal: 0,
            maxPage: 2,
            dataEnd: 0,
        };
    },
    watch: {
        territory: function() {
            this.getData();
        },
        salesChannel: function() {
            this.getData();
        },
        customerCategory: function() {
            this.getData();
        },
        search: function() {
            this.filterData();
        }
    },
    computed: {
        currentPage: {
            get() {
                return 1;
            },
            set(page) {
                this.page(page);
            }
        }
    },
    methods: {
        dateFormat(date) {
            if (date) {
                return moment(String(date)).utcOffset(0).format('DD/MM/YYYY hh:mm')
            }
        },

        formatCurrency(nominal) {
            if(nominal) {
                nominal = (nominal/1).toFixed(2).replace('.', ',');
                return nominal.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
            } else {
                return 0;
            }
        },

        scrollToTop() {
            window.scrollTo(0,0);
        },

        clickEdit(template) {
            this.$emit("selectTemplate", template);
            this.$emit("redirectTab", 2);
        },

        clickDelete(template) {
            this.$vs.dialog({
                type: "confirm",
                color: "danger",
                title: `Confirm`,
                text: "You are about to delete this template?",
                accept: this.actionDelete,
                parameters: template
            });
        },

        clickOrder(template) {
            this.$vs.dialog({
                type: "confirm",
                color: "success",
                title: `Confirm`,
                text: "You are about to make an Sales Order based on this template?",
                accept: this.actionOrder,
                parameters: template
            });
        },

        actionDelete(template) {
            this.$vs.loading();
            this.$http
                .post("api/v1/closed-contract/template/delete", {
                    closed_contract_template_id: template.DefaultAttribute.ID,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: "This template successfully deleted",
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        // this.getData();
                        this.$emit("selectContract", {});
                        this.$emit("redirectTab", 0);
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    }
            });
        },

        actionOrder(template) {
            this.$vs.loading();
            this.$http
                .post("api/v1/closed-contract/template/order", {
                    closed_contract_template_id: template.DefaultAttribute.ID,
                })
                .then(resp => {
                    if (resp.code == 200) {
                        this.$vs.notify({
                            color: "success",
                            title: "Success",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        // this.getData();
                        this.$emit("selectContract", {});
                        this.$emit("redirectTab", 0);
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                        this.getData();
                        this.$vs.loading.close();
                    }
            });
        },

        closeDetail() {
            this.detail = false;
            this.$emit("selectContract", null);
        },

        page(page) {
            this.getData(page);
            return page;
        },

        changeLimit(limit) {
            this.limitShow = limit;
            this.getData(this.isActive);
        },

        getData(page = 1) {
            if(this.territory != null && this.territory != "" && this.territory != undefined) {
                this.isActive = page;
                this.$vs.loading();

                this.$http.get("/api/v1/closed-contract", {
                    params: {
                        territory_code: this.territory,
                        sales_channel_code: this.salesChannel,
                        customer_category_id: this.customerCategory,
                        approval_status: 0,
                        search: this.search,
                        limit: this.limitShow,
                        offset: this.limitShow * (page - 1)
                    }
                }).then(resp => {
                    if (resp.code == 200) {
                        this.drawData = this.drawData + 1;
                        this.dataContract = resp.data.Contracts;
                        this.recordsTotal = resp.data.RecordsTotal;
                        this.maxPage = Math.ceil(this.recordsTotal / this.limitShow);
                        this.dataEnd = resp.data.ResultsTotal;
                        this.checkedAll = false;
                        this.$vs.loading.close();
                    } else {
                        this.$vs.notify({
                            color: "danger",
                            title: "Error",
                            text: resp.message,
                            position: "top-right",
                            iconPack: "feather",
                            icon: "icon-x-circle"
                        });
                    }
                });
            }
        },
    }
};
</script>

<style>
.core-enter-active {
  transition: all 0.3s ease;
}
.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}
.core-enter, .core-leave-to
/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}
.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}
.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}
</style>